import { graphql, Link } from 'gatsby';
import React from 'react';

import { css } from "@emotion/react"
import styled from "@emotion/styled";

import SiteNav from '../components/header/SiteNav';
import { PostCard } from '../components/PostCard';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import { colors } from '../styles/colors';
import { inner, outer, PostFeed, SiteHeader, SiteNavMain, NavBarBackground } from '../styles/shared';
import { PageContext } from '../templates/post';
import config from '../website-config';
import { Divider } from '../templates/index'

interface NotFoundTemplateProps {
  data: {
    allMdx: {
      totalCount: number;
      edges: Array<{
        node: PageContext;
      }>;
    };
  };
}

const NotFoundPage: React.FC<NotFoundTemplateProps> = props => {
  const { edges } = props.data.allMdx;

  return (
    <IndexLayout>
      <Wrapper>
        <header css={[SiteHeader, outer]}>
          <div css={[outer, SiteNavMain, NavBarBackground]}>
            <div css={inner}>
              <SiteNav isHome={false} />
            </div>
          </div>
        </header>
        <main id="site-main" css={[outer, ErrorContent]} className="error-content">
          <div css={[inner]}>
            <section style={{ textAlign: 'center' }}>
              <ErrorCode>404</ErrorCode>
              <ErrorDescription>Page not found</ErrorDescription>
              <br/>
              <p style={{fontSize:"16pt", lineHeight:"1.7em"}}>
                If you have been redirected to this page after clicking on a link
                in one of the articles, it means that the article you are looking for has
                not been published yet. I apologize for the inconvenience
                and assure you that I am working hard to publish that article in
                the near future! In the meantime, you can try and search for a different
                article or go back to the home page.
              </p>
              <br/>
              <Link css={ErrorLink} to={"/"}>
               Go to the front page 🏠
              </Link>
              <br/>
              <Link css={ErrorLink} to={"/SearchPage"}>
                Search for a term 🔎
              </Link>
            </section>

            <br/>
            <Divider linewidth="85%">Or take a look at these posts</Divider>

            <div css={PostFeed} className="post-feed">
              {edges.map(({ node }) => (
                <PostCard key={node.fields.slug} post={node} />
              ))}
            </div>
          </div>
        </main>
      </Wrapper>
    </IndexLayout>
  );
};

export const pageQuery = graphql`{
  allMdx(
    limit: 3
    sort: {fields: [frontmatter___date], order: DESC}
    filter: {frontmatter: {draft: {eq: false}}}
  ) {
    edges {
      node {
        frontmatter {
          title
          date
          tags
          draft
          excerpt
          timeToRead
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          author {
            id
            bio
            avatar {
              children {
                ... on ImageSharp {
                  fluid(quality: 100, srcSetBreakpoints: [40, 80, 120]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        excerpt
        fields {
          layout
          slug
        }
      }
    }
  }
}
`;

const ErrorContent = css`
  padding: 14vw 4vw 6vw;

  @media (max-width: 800px) {
    padding-top: 24vw;
  }

  @media (max-width: 500px) {
    padding-top: 28vw;
  }

  @media (min-width: 940px) {
    .post-card {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }
`;

const ErrorCode = styled.h1`
  margin: 0;
  /* color: var(--lightgrey); */
  color: ${colors.lightgrey};
  font-size: 12vw;
  line-height: 1em;
  letter-spacing: -5px;
  opacity: 0.75;

  @media (max-width: 800px) {
    font-size: 11.2rem;
  }
`;

const ErrorDescription = styled.p`
  margin: 0;
  /* color: var(--midgrey); */
  color: ${colors.midgrey};
  font-size: 3rem;
  line-height: 1.3em;
  font-weight: 400;

  @media (max-width: 800px) {
    margin: 5px 0 0 0;
    font-size: 1.8rem;
  }
`;

const ErrorLink = css`
  display: inline-block;
  margin-top: 5px;
`;

export default NotFoundPage;
